import "./stylesheets/App.css";
import Home from "./sections/Home";
import About from "./sections/About";
import Services from "./sections/Services";
import Approach from "./sections/Approach";
import Why from "./sections/Why";
import Contact from "./sections/Contact";
import Project from "./sections/Project";
import Nav from "./sections/Nav"

//import library
import React from "react";
import SplitType from "split-type";

//import data
import contents from "./data/content.json";
import gsap from "gsap";

const App = () => {
    //select content based on language setting
    const data = contents.en;

    //split text functions
    const splitText = (target) => {
        return new SplitType(target, {
            types: "words",
            tagName: "span",
        });
    };

    //animation Enter Text Only
    const paragraphAnimation = (element, trigger) => {
        gsap.from(element, {
            opacity: 0,
            color: "#999999",
            ease: "back.inOut",
            x: "30%",
            //scale:4,
            stagger: 0.02,
            scrollTrigger: {
                trigger: trigger,
                start: "top 70%",
                end: "bottom 70%",
                scrub: 1,
                //markers: true,
            }
        });
    };

    //animation Enter Text Only
    const sloganAnimation = (element, trigger) => {
        gsap.from(element, {
            opacity: 0,
            color: "#999999",
            ease: "back.inOut",
            y: "50%",
            //scale:2,
            stagger: 0.1,
            scrollTrigger: {
                trigger: trigger,
                start: "top 70%",
                end: "bottom 70%",
                scrub: 2,
                //markers: true,
            }
        });
    };

    //animation Enter Text Only
    const gridAnimation = (element, trigger) => {
        gsap.from(element, {
            opacity: 0,
            color: "#999999",
            ease: "power3.out",
            x: "100%",
            //scale:4,
            stagger: 0.1,
            scrollTrigger: {
                trigger: trigger,
                start: "top 70%",
                end: "bottom 70%",
                scrub: 1,
                //markers: true,
            }
        });
    };


    //animation list
    const animation = {paragraphAnimation, sloganAnimation, gridAnimation}


    return (
        <div className="app">

            <Nav/>

            <Home/>
            <About
                vision={data.vision}
                about={data.about}
                slogan={data.sloganThree}
                splitText={splitText}
                animation={animation}

            />
            <Why why={data.why} animation={animation}/>
            <Services services={data.services} animation={animation}/>
            <Approach approach={data.approach} animation={animation}/>
            <Project animation={animation}/>
            <Contact/>


        </div>
    );
};

export default App;

/*      <nav>
<a href="#home">Home</a>
<a href={"#about"}>About</a>
<a>Services</a>
<a>Approach</a>
<a>Project</a>
<a>Why Us</a>
<a href="#contact">Contact</a>
</nav>


<Home /> 
<About vision={data.vision} about={data.about} slogan={data.sloganThree}/> 
<Services services = {data.services}/>  
<Approach approach={data.approach}/>
<Project /> 
<Why /> 
<Contact /> 
*/

import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, {useLayoutEffect, useRef} from "react";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const About = ({vision, about, slogan, splitText, animation}) => {
    const compRef = useRef(null);

    gsap.registerPlugin(ScrollTrigger);

    //animation
    useLayoutEffect(() => {

        //split the text into lines
        splitText("h2");
        splitText("p");
        splitText("h3");
        splitText("h4");

        const ctx = gsap.context(() => {



            animation.sloganAnimation(".slogan .word", ".slogan")
            animation.paragraphAnimation(".about-content .word", ".about-content")
            animation.paragraphAnimation(".vision-content .word", ".vision-content")

        }, compRef);

        return () => ctx.revert();
    }, []);


    return (
        <div className="about flex-center" id="about" ref={compRef}>
            {/* Slogan */}
            <section>
                <Grid container md={8}>
                    <Grid md={12}>
                        <h2 className="slogan">{slogan}</h2>
                    </Grid>
                </Grid>
            </section>
            <section>
            <Grid container spacing={4} md={8}>
                <Grid mdOffset={5} md={7} className="about-content">
                    {/* ABOUT*/}
                    <h3>About</h3>
                    {about.content.split("\n").map((str) => (
                        <p>{str}</p>
                    ))}
                </Grid>
                <Grid md={5} className="vision-content">
                    {/* Vision */}
                    <h3>{vision.title}</h3>
                    <p>{vision.content}</p>
                </Grid>
            </Grid>
            </section>
        </div>
    );
};

export default About;

import React, {useLayoutEffect, useRef} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const Services = ({ services, animation}) => {

    const compRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    //animation
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            animation.sloganAnimation(".services h2 .word", ".services h2")
            animation.gridAnimation(".services .line", ".service-content")

        }, compRef);
        return () => ctx.revert();
    }, []);

  return (
    <section className="services flex-center" id="services" ref={compRef}>
      <Grid container rowSpacing={8} md={8} className="service-content">

          {/*Title*/}
        <Grid md={12}>
          <h2>Services</h2>
        </Grid>

          {/*Service Content*/}
        {services.map((service, index) => (
          <Grid
            className={`service line ${
              index !== services.length - 1 && "border-bottom"
            }`}
            container
            key={service.id}
            md={12}
            spacing={0}
          >
            <Grid md={4} >
              <h4>{service.title}</h4>
            </Grid>
            <Grid md={8}>
              <p>{service.content}</p>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default Services;

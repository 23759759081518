import React, { useLayoutEffect,useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import bg from "../../img/homeBG.jpg";

const Home = () => {
  const homeRef = useRef();
  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // use scoped selectors
      gsap.to(".home img", {
        opacity: 0,
        scrollTrigger: {
          trigger: ".home h1", // Change the trigger to titleRef
          start: "center center",
          end: "center top",
          scrub: 2,
          //markers: true

        },
      });

      
    }, homeRef);
    
    return () => ctx.revert();
  }, []);

  return (
    <div className="home flex-center" style={{ height: "100vh" }} ref={homeRef} id="home">
      <div className="home-bg">
        <img src={bg} alt={"bg"} />
      </div>
      <h1>The Soil</h1>
    </div>
  );
};

export default Home;
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Nav = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [shouldShowNav, setShouldShowNav] = useState(false);
    const compRef = useRef();
    const tl = useRef();

    // Set up the animation and ScrollTrigger
    // Set up the animation and ScrollTrigger
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            tl.current && tl.current.progress(0).kill();
            tl.current = gsap.timeline()
            tl.current.to(".menu", {
                y: 0,
                duration: 0.5,
                ease: "expo.out"
            });
            tl.current.from(".menu nav a", {
                y: 40,
                opacity: 0,
                duration: 0.2,
                ease: "back.out",
                stagger: 0.1
            });

        }, compRef);

        return () => ctx.revert();
    }, []);

    useEffect(() => {
        tl.current.reversed(!isMenuOpen);
    }, [isMenuOpen]);


    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const homeSection = document.getElementById("home");

            if (homeSection) {
                const homeSectionTop = homeSection.offsetTop;
                const homeSectionHeight = homeSection.offsetHeight;

                // Set shouldShowNav based on the scroll position within the "home" section
                setShouldShowNav(!(scrollPosition >= homeSectionTop && scrollPosition <= homeSectionTop + homeSectionHeight/2));
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleToggleClick = () => {
        setMenuOpen(!isMenuOpen);
    };

    return  (
        <div ref={compRef} className={!shouldShowNav && "hidden"}  >
            {/* Open menu button */}
            <button className={`menu-toggle`} onClick={handleToggleClick}>
                <FontAwesomeIcon icon={faBars} />
            </button>

            <span className="logo">The Soil</span>

            {/* Menu */}

            {/* Navigation links */}
            <div className="menu">
                {/* Close menu button */}
                <button className={`menu-toggle`} onClick={handleToggleClick}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>

                <span className="logo">The Soil</span>

                <nav onClick={handleToggleClick}>
                    <a href="#home">Home</a>
                    <a href="#about">About</a>
                    <a href="#services">Services</a>
                    <a href="#approach">Approach</a>
                    <a href="#project">Project</a>
                    <a href="#contact">Contact</a>
                </nav>
            </div>
        </div>
    )
};

export default Nav;

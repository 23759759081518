
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import React, {useLayoutEffect, useRef} from "react";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Why = ({ why, animation }) => {
    const compRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);


    // Set up the animation and ScrollTrigger
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            const timeline = gsap.timeline();
            animation.paragraphAnimation(".why-content .word", ".why-content")

        }, compRef);

        return () => ctx.revert();
    }, []);

    return (
        <div className="why flex-center" id="why" ref={compRef}>
        <section>
            <Grid container md={4} rowSpacing={4} className="why-content">
                <Grid md={12}>
                    <h3>Why Choose Us</h3>
                </Grid>
                <Grid md={12}>
                    <h4>{why.solutions.title}</h4>
                    <p>{why.solutions.content}</p></Grid>
                    <Grid md={12}>

                    <h4>{why.team.title}</h4>
                        <p>{why.team.content}</p></Grid>
                        <Grid md={12}>

                    <h4>{why.innovation.title}</h4>
                    <p>{why.innovation.content}</p>
                </Grid>
            </Grid>
        </section>
        </div>
    );
};

export default Why;

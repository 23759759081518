import React, {useState} from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

const Contact = () => {

  const [content, setContent] = useState("");

  return (
      <section className="contact flex-center" id="contact" style={{paddingBottom:"20vh"}}>
          <h2>Contact Us</h2>
          <br/>
        <h3>thesoil@thesoil.net</h3>

      </section>
  );
};

export default Contact;
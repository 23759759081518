import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import yichuma from "../../img/yichuma.jpeg";
import yangkehan from "../../img/yangkehan.jpeg";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const Project = ({animation}) => {
    const compRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    //animation
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            animation.sloganAnimation(".project-detail", ".project")
            animation.sloganAnimation(".project h2", ".project h2")

        }, compRef);
        return () => ctx.revert();
    }, []);
  return (
    <section className="flex-center" id="project" ref={compRef}>
      <Grid container md={8} spacing={8} className="project">
          <Grid xs={12}><h2>Projects</h2></Grid>
        <Grid className="project-detail" md={6}><img src={yangkehan}/></Grid>
          <Grid className="project-detail"md={6}><p>YANGKEHAN is a brand  basing on oriental aesthetics  with typical silhouettes
              depicting eastern appreciation of womenswear which was established by Kehan Yang in 2021.</p></Grid>

          <Grid className="project-detail"className="project-detail" md={6} ><img src={yichuma}/></Grid>
          <Grid className="project-detail"md={6}><p>Yid'phrogma is a womenswear brand by a specialist designer with Tibetan culture as the core tone. Not only does Yid' phrogma incorporate Tibetan art and folklore, but also it aims to integrate the spiritual beliefs and cultural connotations of Tibetan culture into the design and philosophy of each collection.</p></Grid>
      </Grid>
    </section>
  );
};

export default Project;
import React, {useLayoutEffect, useRef} from "react";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from "gsap";


const Approach = ({approach, animation}) => {

    const compRef = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    //animation
    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            animation.sloganAnimation(".approach-intro h2 .word", ".approach-intro h2")
            animation.paragraphAnimation(".approach-intro p .word", ".approach-intro p")
            animation.gridAnimation(".approach-content .line", ".approach-content")

        }, compRef);
        return () => ctx.revert();
    }, []);

    return (
        <div className="approach flex-center" id="approach" ref={compRef}>

            <section className="flex-center approach-intro">
                <Grid container md={8} rowSpacing={8}>
                    <Grid md={12}>
                        <h2 className="slogan">
                        Our Approach to Collaborate with You to Kickstart Your Journey
                    </h2>
                    </Grid>
                    <Grid md={12}><p>{approach.intro.content}</p></Grid>
                </Grid>
            </section>

            <section className="flex-center approach-content">

                <Grid container md={8} rowSpacing={8}>
                    {approach.steps.map((step, index) => (
                        <Grid
                            className={`line ${
                                index !== approach.steps.length - 1 && "border-bottom"
                            }`}
                            container
                            key={step.id}
                            md={12}
                            spacing={0}
                        >
                            <Grid md={4}>
                                <h4>
                                    <span className="number">{index + 1}</span> {step.title}
                                </h4>
                            </Grid>

                            <Grid md={8}>
                                <p>{step.content}</p>
                            </Grid>

                        </Grid>

                    ))}</Grid>
            </section>

        </div>
    );
};

export default Approach;
